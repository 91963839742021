<template>
  <div class="non-scrollable">
    <DataTable ref="dt" :value="facturefournisseurs" :paginator="true" class="p-datatable-customers" :rows="constants.defaultRowNumber"
               dataKey="id" :rowHover="true" v-model:filters="filters" filterDisplay="menu" selection-mode="single"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="constants.rowsPerPageOptions"
               :currentPageReportTemplate="$t('datatable.currentPageReportTemplate', {first: '{first}', last: '{last}', totalRecords: '{totalRecords}', target: $t('datatable.target.data')})"
               scrollable scroll-height="flex" @rowSelect="clickFacturefournisseur">
      <template #header>
        <div class="p-d-flex flex justify-content-between">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="filters['global'].value" :placeholder="$t('search')" />
          </span>
          <div>
            <FileUpload ref="upload" style="display: none" mode="basic" auto custom-upload @uploader="onUpload" accept="image/png, image/jpeg, application/pdf" :maxFileSize="10000000" />
            <Button type="button" label="Nouvelle facture fournisseur" icon="pi pi-plus" @click="toggleMenu" />
            <Menu ref="menu" :model="itemsNouvelleFacturefournisseur" :popup="true" />
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('datatable.notFound', {target: $t('datatable.target.data')}) }}
      </template>
      <template #loading>
        {{ $t('datatable.load', {target: $t('datatable.target.data')}) }}
      </template>
      <Column field="independent.last_name" :header="$t('lastname').toUpperCase()" sortable filterField="independent.last_name" sortField="independent.last_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('lastname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="lastnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="independent.first_name" :header="$t('firstname').toUpperCase()" sortable filterField="independent.first_name" sortField="independent.first_name" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">{{ $t('firstname') }}</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="firstnamesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="nature" :header="$t('nature').toUpperCase()" sortable filterField="nature" sortField="nature" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Nature</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="naturesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
      <Column field="date" :header="$t('date').toUpperCase()" sortable sortField="date">
        <template #body="{data}">
          <span> {{ getDateFormatted(data.date) }}</span>
        </template>
      </Column>
      <Column field="ht" :header="$t('ht').toUpperCase()" sortable sortField="ht">
        <template #body="{data}">
          <span> {{ getEuroFormat(data.ht) }}</span>
        </template>
      </Column>
      <Column field="tva" :header="$t('tva').toUpperCase()" sortable sortField="tva">
        <template #body="{data}">
          <span> {{ getEuroFormat(data.tva) }}</span>
        </template>
      </Column>
      <Column field="ttc" :header="$t('ttc').toUpperCase()" sortable sortField="ttc">
        <template #body="{data}">
          <span> {{ getEuroFormat(data.ttc) }}</span>
        </template>
      </Column>
      <Column field="refacturable" :header="$t('refacturable').toUpperCase()" sortable filterField="refacturable" sortField="refacturable" :showFilterMatchModes="false" :show-apply-button="false" :show-clear-button="false">
        <template #body="{data}">
          <div class="align-items-center flex">
            <Checkbox binary :disabled="true" v-model="data.refacturable"></Checkbox>
            <div class="ml-2" v-if="data.refacturable && data.mission">{{ data.mission.clientContract.name }}</div>
          </div>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <div class="p-mb-3 p-text-bold mb-3">Refacturable</div>
          <MultiSelect @change="filterCallback()" v-model="filterModel.value" :options="refacturablesFilter" option-value="value" optionLabel="value" :placeholder="$t('all')" class="p-column-filter" style="max-width: 14rem; min-width: 14rem">
            <template #option="slotProps">
              <div>
                <span>{{slotProps.option.value}}</span>
              </div>
            </template>
          </MultiSelect>
        </template>
      </Column>
    </DataTable>
  </div>
  <DialogFactureFournisseurAdmin @reload="loadData" @close="close" v-if="dialogNewFacturefournisseur" :dialog="dialogNewFacturefournisseur" :file="facture" :ocr="ocrResult" :facturefournisseur="currentFacturefournisseur" :independents="independents" :missions="missions"></DialogFactureFournisseurAdmin>
</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import * as Money from '@/utils/Money';
import * as Date from '@/utils/Date';
import DialogFactureFournisseurAdmin from "@/components/NDF/DialogFactureFournisseurAdmin";
import {mapState} from "vuex";

export default {
  name: "TableauFactureFournisseurAdmin",
  components: {DialogFactureFournisseurAdmin},
  emits: ["reload"],
  props: ['facturefournisseurs', 'missions', 'independents'],
  data() {
    return {
      itemsNouvelleFacturefournisseur: [
        {
          label: 'Facture',
          icon: 'pi pi-file',
          command: () => {
            this.$refs.upload.choose()
          }
        },
        {
          label: 'Saisir',
          icon: 'pi pi-pencil',
          command: async () => {
            this.currentFacturefournisseur = null
            this.ocrResult = null
            this.facture = null
            this.dialogNewFacturefournisseur = true
          }
        },
      ],
      getEuroFormat: Money.getEuroFormat,
      getDateFormatted: Date.getDateFormatted,
      filters: {
        'global': {value: null, matchMode: null},
        'independent.last_name': {value: null, matchMode: FilterMatchMode.IN},
        'independent.first_name': {value: null, matchMode: FilterMatchMode.IN},
        'nature': {value: null, matchMode: FilterMatchMode.IN},
      },
      exportSheetLoading: false,
      jsonTest: {
        "result": "success",
        "data": {
          "document_type": "receipt",
          "amount": 720,
          "amount_change": 1280,
          "amount_shipping": 0,
          "vatamount": 41,
          "amountexvat": 679,
          "currency": "EUR",
          "date": "2018-02-10T19:35:00",
          "purchasedate": "2018-02-10",
          "purchasetime": "19:35:00",
          "vatitems": [
            {
              "amount": 41,
              "amount_excl_vat": 679,
              "amount_incl_vat": 720,
              "amount_incl_excl_vat_estimated": false,
              "percentage": 600,
              "code": ""
            }
          ],
          "vat_context": "",
          "lines": [
            {
              "description": "",
              "lineitems": [
                {
                  "title": "Big Mac Meal\n M Fanta Orange",
                  "description": "",
                  "amount": 720,
                  "amount_each": 720,
                  "amount_ex_vat": 679,
                  "vat_amount": 41,
                  "vat_percentage": 600,
                  "quantity": 1,
                  "unit_of_measurement": "",
                  "sku": "",
                  "vat_code": ""
                }
              ]
            }
          ],
          "paymentmethod": "cash",
          "payment_auth_code": "",
          "payment_card_number": "",
          "payment_card_account_number": "",
          "payment_card_bank": "",
          "payment_card_issuer": "",
          "payment_due_date": "",
          "terminal_number": "",
          "document_subject": "",
          "package_number": "",
          "invoice_number": "",
          "invoice_type": "",
          "receipt_number": "",
          "shop_number": "",
          "transaction_number": "",
          "transaction_reference": "",
          "order_number": "",
          "table_number": "",
          "table_group": "",
          "server": "",
          "merchant_name": "Mcdonald's Restaurant",
          "merchant_id": "",
          "merchant_coc_number": "",
          "merchant_vat_number": "",
          "merchant_bank_account_number": "",
          "merchant_bank_account_number_bic": "",
          "merchant_chain_liability_bank_account_number": "",
          "merchant_chain_liability_amount": 0,
          "merchant_bank_domestic_account_number": "",
          "merchant_bank_domestic_bank_code": "",
          "merchant_website": "",
          "merchant_email": "",
          "merchant_address": "",
          "merchant_street_name": "",
          "merchant_house_number": "",
          "merchant_zipcode": "",
          "merchant_city": "",
          "merchant_municipality": "",
          "merchant_province": "",
          "merchant_country": "",
          "merchant_country_code": "",
          "merchant_phone": "6166691437",
          "merchant_main_activity_code": "",
          "customer_name": "",
          "customer_number": "",
          "customer_reference": "",
          "customer_address": "",
          "customer_street_name": "",
          "customer_house_number": "",
          "customer_zipcode": "",
          "customer_city": "",
          "customer_municipality": "",
          "customer_province": "",
          "customer_country": "",
          "customer_phone": "",
          "customer_vat_number": "",
          "customer_coc_number": "",
          "customer_bank_account_number": "",
          "customer_bank_account_number_bic": "",
          "customer_website": "",
          "customer_email": "",
          "payment_slip_code": "",
          "payment_slip_reference_number": "",
          "payment_slip_customer_number": "",
          "document_language": "EN",
          "matched_keywords": null,
          "matched_lineitems": null,
          "matched_purchase_order_id": "",
          "barcodes": null,
          "hash": "51733b4e855766a53e56f3c5ad2d9108a43f4098",
          "hash_duplicate": false,
          "raw_text": "           1385\nBUY ONE GET ONE FREE QUARTER POUNDER\n       W/CHEESE OR EGG MCMUFFIN\nGo to www.mcdvoice.com within 7 days\n     and tell us about your visit.\n  Validation Code:\nExpires 30 days after receipt date.\nValid at participating US McDonald's.\n              Survey Code:\n    10055-13851-00218-19359-00072-0\n      McDonald's Restaurant #10055\n            4596 32ND AVENUE\n       HUDSONVILLE, MI 49426-9499\n           TEL# 616 669 1437\nKS# 13                10/02/2018 07:35 PM\nSide1                           Order 85\n1 Big Mac Meal                      6.79\n   1 M Fanta Orange\nSubtotal                            6.79\n   Tax                              0.41\nTake-Out Total                      7.20\nCash Tendered                      20.00\nChange                             12.80\n         We Welcome Your Comments\n           Come Join Our Team\n     Apply at www.mcmichigan.com/10055"
        },
        "request_id": ""
      },
      facture: null,
      dialogNewFacturefournisseur: false,
      ocrResult: null,
      currentFacturefournisseur: null,
    }
  },
  computed: {
    ...mapState({
      constants: state => state.constants,
    }),
    naturesFilter () {
      let array = this.facturefournisseurs.map((d) => d.nature)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    refacturablesFilter () {
      let array = this.facturefournisseurs.map((d) => d.refacturable)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    firstnamesFilter () {
      let array = this.facturefournisseurs.map((p) => p.independent.first_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    },
    lastnamesFilter () {
      let array = this.facturefournisseurs.map((p) => p.independent.last_name)
      let filterArray = []
      array.forEach((item) => {
        if (!filterArray.find((fa) => fa.value === item)) {
          filterArray.push({
            value: item
          })
        }
      })
      return filterArray
    }
  },
  methods: {
    close () {
      this.dialogNewFacturefournisseur = false
      this.facture = null
      this.ocrResult = null
      this.currentFacturefournisseur = null
    },
    loadData () {
      this.$emit('reload')
    },
    async onUpload (file) {
      this.$store.state.misc.loading = true
      await Promise.all([new Promise((resolve) => setTimeout(() => {resolve()}, 2000))])
      this.$store.state.misc.loading = false
      this.facture = file
      this.ocrResult = this.jsonTest
      this.dialogNewFacturefournisseur = true
    },
    toggleMenu (event) {
      this.$refs.menu.toggle(event)
    },
    async clickFacturefournisseur (facturefournisseur) {
      this.currentFacturefournisseur = facturefournisseur.data
      // this.missions = await this.$store.dispatch('mission/getMissionsByIndependent', facturefournisseur.data.independent)
      this.dialogNewFacturefournisseur = true
    },
  }
}
</script>

<style scoped>
</style>


